<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark mb-4">
      <a
        class="navbar-brand"
        href="#"
      >Helper</a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon" />
      </button>
      <div
        id="navbarNav"
        class="collapse navbar-collapse"
      >
        <ul class="navbar-nav">
          <li :class="paginaAtual == 'geradorTabela' ? 'nav-item active' : 'nav-item'">
            <a
              class="nav-link"
              href="#"
              @click.prevent="paginaAtual = 'geradorTabela'"
            >Gerador de tabelas</a>
          </li>
          <li :class="paginaAtual == 'leitorTabela' ? 'nav-item active' : 'nav-item'">
            <a
              class="nav-link"
              href="#"
              @click.prevent="paginaAtual = 'leitorTabela'"
            >Leitor de tabelas</a>
          </li>
        </ul>
      </div>
    </nav>
    <!-- Gerador de tabela -->
    <div v-if="paginaAtual == 'geradorTabela'">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1>Gerador de tabela</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <form
              id="formScript1"
              action
              method="POST"
            >
              <!-- row -->
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for>Nome da tabela</label>
                    <input
                      id
                      type="text"
                      name="nomeTabela"
                      class="form-control"
                      placeholder
                      aria-describedby="helpId"
                    >
                    <small
                      id="helpId"
                      class="text-muted"
                    />
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label for>Número de colunas</label>
                    <input
                      id
                      type="number"
                      name="numeroColunas"
                      class="form-control"
                      placeholder
                      min="1"
                      max="30"
                    >
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label
                      class="invisible d-block"
                      for
                    >Gerar</label>
                    <button
                      name="btnGerar"
                      type="submit"
                      class="btn btn-primary d-none"
                    >
                      Gerar
                    </button>
                  </div>
                </div>
              </div>
              <!-- / row -->
              <!-- row -->
              <div
                id="tabelaColunas"
                class="row"
              />
              <!-- / row -->
            </form>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <!-- row -->
        <div
          id="resultadoTabela"
          class="row"
        />
        <!-- / row -->
        <!-- row -->
        <div
          id="resultadoProc"
          class="row"
        />
        <!-- / row -->
        <!-- row -->
        <div
          id="resultadoController"
          class="row"
        />
        <!-- / row -->

        <!-- row -->
        <div
          id="resultadoJavascript"
          class="row"
        />
        <!-- / row -->
      </div>
      <datalist id="tamanhos" />
    </div>
    <div v-else-if="paginaAtual == 'leitorTabela'">
      <div class="container">        
        <div class="row">
          <div class="col-12">
            <h1>
              Leitor de tabelas <button
                v-if="!fastColunas.length"
                class="btn btn-sm btn-primary ml-2"
                @click="lerTabela()"
              >
                Ler tabela
              </button> <button
                class="btn btn-sm btn-secondary ml-2"
                @click="limparLerTabela()"
              >
                Limpar
              </button>
            </h1>            
          </div>
        </div>
        <div
          v-if="!fastColunas.length"
          class="row"
        >
          <div class="col-6 mt-4">
            <label>Nome do componente</label>
            <input
              v-model="fastNomeComponente"
              type="text"
              class="form-control"
            >
          </div>
          <div class="col-6 mt-4">
            <label>Tipo de página</label>
            <select
              v-model="fastTipoPagina"
              class="form-control"
            >
              <option value="HomeInternoAdmin">
                HomeInternoAdmin
              </option>
              <option value="HomeInternoAluno">
                HomeInternoAluno
              </option>
            </select>
          </div>
          <div class="col-6 mt-4">
            <label>Título da página</label>            
            <input
              v-model="fastTituloPagina"
              type="text"
              class="form-control"
            >
          </div>
          <div class="col-12 mt-4">
            <label>Cole o código de criação da tabela aqui</label>
            <br>
            <textarea
              v-model="fastTabelaLide"
              cols="100"
              rows="30"
              class="form-control"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <b-tabs content-class="mt-3">
              <b-tab
                title="VUE - Template vazio"
                active
              >
                <pre class="text-info">{{ fastVueTemplateEmpty }}</pre>
              </b-tab>
              <b-tab title="VUE - HTML">
                <pre class="text-info">{{ html }}</pre>
              </b-tab>
              <b-tab title="VUE - Métodos">
                <pre class="text-info">{{ methods }}</pre>
              </b-tab>
              <b-tab title="VUE - Script">
                <pre class="text-info">{{ script }}</pre>
              </b-tab>
              <b-tab title="VUE - Style">
                <pre class="text-info">{{ style }}</pre>
              </b-tab>
              <b-tab title="VUE - Template completo">
                <pre class="text-info">{{ fastVueTemplateFull }}</pre>
              </b-tab>
              <b-tab title="API - Controller">
                <pre class="text-info">{{ fastController }}</pre>
              </b-tab>
              <b-tab title="SQL - Procedure">
                <pre class="text-info">{{ fastProc }}</pre>
              </b-tab>              
            </b-tabs>     
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      paginaAtual: "leitorTabela",      
      fastCurso: [],
      fastTabelaLide: "",
      fastTabela: "Aguardando leitura...",
      fastColunas: [],
      fastChavesPrimarias: [],
      fastVueTemplateFull: "",
      fastVueTemplateEmpty: "",
      html: "",
      script: "",
      style: "",
      methods: "",
      fastNomeComponente: "HomeInternoAdminExemplo",
      fastTipoPagina: "HomeInternoAdmin",
      fastTituloPagina: "Gerenciamento de exemplos",
      fastRota: "/plataforma/:id_plataforma/admin/exemplos",
      fastController: "",
      fastProc: ""
    };
  },
  mounted: function () {
    if (this.paginaAtual == "geradorTabela") {
      let formScript1 = document.querySelector("#formScript1");

      let div = document.querySelector("#tabelaColunas");

      let geraColunas = (e) => {
        let n = e.target.value;
        if (n < 21) {
          div.innerHTML = "";
          for (let i = 0; i < n; i++) {
            let template = `
              <!-- col-4 -->
                      <div class="col-4 p-4 border border-secondary">
                      <h3>Coluna ${i + 1}</h3>
                          <div class="form-group">
                          <label for="">Nome da coluna</label>
                          <input
                              type="text"
                              id=""
                              nomeColuna="${i + 1}"
                              class="form-control"
                              placeholder=""
                          />
                          <div class="form-group">
                              <label for="">Tipo de dados</label>
                              <select tipoColuna="${
                                i + 1
                              }" class="form-control" name="" id="">
                              <option value="int">Int</option>
                              <option value="char">Char</option>
                <option value="char">NChar</option>
                              <option value="varchar">Varchar</option>
                              <option value="nvarchar">NVarchar</option>
                              <option value="date">Date</option>
                              <option value="datetime">Datetime</option>
                <option value="bit">Bit</option>
                <option value="time">Time</option>
                              </select>
                          </div>
                          <div class="form-group">
                              <label for="">Tamanho</label>
                              <input
                              tamanhoColuna="${i + 1}"
                              type="text"
                              id=""
                              class="form-control"
                              placeholder=""
                              value=""
                              list="tamanhos"
                              />
                          </div>
                          <div class="form-group">
                              <label for="">Chave não inserível</label>
                              <select chaveColuna="${
                                i + 1
                              }" class="form-control" name="" id="">
                              <option value="0">Não</option>
                              <option value="1">Sim</option>
                              </select>
                          </div>
                      </div>
                      </div>
                      <!-- / col-4 -->
              `;
            div.innerHTML += template;
          }
        } else {
          div.innerHTML =
            "<div class='col-12 text-center'><h4>O limite é 20 colunas</h4></div>";
        }
      };

      formScript1.numeroColunas.onkeyup = function (e) {
        geraColunas(e);
      };
      formScript1.numeroColunas.onchange = function (e) {
        geraColunas(e);
      };

      formScript1.numeroColunas.onblur = function (e) {
        if (e.target.value > 0 && e.target.value < 21) {
          e.target.setAttribute("readonly", "readonly");
          formScript1.btnGerar.classList.remove("d-none");
        }
      };

      formScript1.onsubmit = (e) => {
        e.preventDefault();
        let arrayColunas = [];
        let nomeColunas = document.querySelectorAll("[nomeColuna]");
        nomeColunas.forEach((e) => {
          if (e.value.trim() != "") {
            let objColuna = {
              sequencia: e.getAttribute("nomeColuna"),
              nomeColuna: e.value.trim(),
              tipoColuna: document.querySelector(
                "[tipoColuna='" + e.getAttribute("nomeColuna") + "']"
              ).value,
              tamanhoColuna: document.querySelector(
                "[tamanhoColuna='" + e.getAttribute("nomeColuna") + "']"
              ).value,
              chaveColuna: document.querySelector(
                "[chaveColuna='" + e.getAttribute("nomeColuna") + "']"
              ).value,
            };
            arrayColunas.push(objColuna);
          }
        });

        let objTabela = {
          nomeTabela: formScript1.nomeTabela.value,
          colunas: [],
        };
        objTabela.colunas = arrayColunas;

        document.querySelector("#resultadoTabela").innerHTML =
          '<div class="col-12"> <h1>Tabela</h1><pre>' +
          this.geraTabela(objTabela) +
          "</pre>";

        document.querySelector("#resultadoProc").innerHTML =
          '<div class="col-12"> <h1>Proc gerada</h1><pre>' +
          this.geraProc(objTabela) +
          "</pre>";

        document.querySelector("#resultadoController").innerHTML =
          '<div class="col-12"> <h1>Controller gerado</h1><pre>' +
          this.geraController(objTabela) +
          "</pre>";

        document.querySelector("#resultadoJavascript").innerHTML =
          '<div class="col-12"> <h1>Métodos javascript</h1><pre>' +
          this.geraJavascript(objTabela) +
          "</pre>";
      };
    }
    
  },
  methods: {
    geraController(objTabela) {
      let nomeTabelaUp = "STO_" + objTabela.nomeTabela.toUpperCase() + "_CRUD";

      // param da proc
      let arrayProcParams = [];
      objTabela.colunas.forEach((e) => {
        arrayProcParams.push("@" + e.nomeColuna.toUpperCase());
      });
      let procParams = arrayProcParams.join(", ");

      // Sql da proc
      let arraySqlParams = [];
      objTabela.colunas.forEach((e) => {
        if (e.chaveColuna == "1") {
          arraySqlParams.push(
            'new SqlParameter("' +
              e.nomeColuna.toUpperCase() +
              '", ' +
              objTabela.nomeTabela +
              "." +
              e.nomeColuna +
              ")"
          );
        } else {
          arraySqlParams.push(
            'new SqlParameter("' +
              e.nomeColuna.toUpperCase() +
              '", DBNull.Value)'
          );
        }
      });
      let sqlParams = arraySqlParams.join(", ");

      return `
            [Authorize]
            [Route("api/${objTabela.nomeTabela}/lista")]
            public async Task&lt;List&lt;${nomeTabelaUp}_Result&gt;&gt; Get${objTabela.nomeTabela}_lista(int id_plataforma)
            {
                db.Configuration.ProxyCreationEnabled = false;
                db.Configuration.LazyLoadingEnabled = false;
    
                // Busca o id_usuario referente ao token
                IOwinContext ctx = Request.GetOwinContext();
                int id_usuario_token = Convert.ToInt32(ctx.Authentication.User.Claims.FirstOrDefault(x =&gt; x.Type == "UserID").Value);
    
                var resultado = await db.Database.SqlQuery&lt;${nomeTabelaUp}_Result&gt;(
                "${nomeTabelaUp} ${procParams}, @ID_USUARIO, @ACAO",
                ${sqlParams},
                new SqlParameter("ID_USUARIO", id_usuario_token),
                new SqlParameter("ACAO", "SELECT")
                ).ToListAsync();
    
                return resultado;
            }
    
            [Authorize]
            [Route("api/${objTabela.nomeTabela}/insere")]
            public async Task&lt;List&lt;${nomeTabelaUp}_Result&gt;&gt; Post${objTabela.nomeTabela}_insere(${objTabela.nomeTabela} ${objTabela.nomeTabela})
            {
                db.Configuration.ProxyCreationEnabled = false;
                db.Configuration.LazyLoadingEnabled = false;
    
                // Busca o id_usuario referente ao token
                IOwinContext ctx = Request.GetOwinContext();
                int id_usuario_token = Convert.ToInt32(ctx.Authentication.User.Claims.FirstOrDefault(x =&gt; x.Type == "UserID").Value);
    
                var resultado = await db.Database.SqlQuery&lt;${nomeTabelaUp}_Result&gt;(
                "${nomeTabelaUp} ${procParams}, @ID_USUARIO, @ACAO",
                ${sqlParams},
                new SqlParameter("ID_USUARIO", id_usuario_token),
                new SqlParameter("ACAO", "INSERT")
                ).ToListAsync();
    
                return resultado;
            }
    
            [Authorize]
            [Route("api/${objTabela.nomeTabela}/atualiza")]
            public async Task&lt;List&lt;${nomeTabelaUp}_Result&gt;&gt; Post${objTabela.nomeTabela}_atualiza(${objTabela.nomeTabela} ${objTabela.nomeTabela})
            {
                db.Configuration.ProxyCreationEnabled = false;
                db.Configuration.LazyLoadingEnabled = false;
    
                // Busca o id_usuario referente ao token
                IOwinContext ctx = Request.GetOwinContext();
                int id_usuario_token = Convert.ToInt32(ctx.Authentication.User.Claims.FirstOrDefault(x =&gt; x.Type == "UserID").Value);
    
                var resultado = await db.Database.SqlQuery&lt;${nomeTabelaUp}_Result&gt;(
                "${nomeTabelaUp} ${procParams}, @ID_USUARIO, @ACAO",
                ${sqlParams},
                new SqlParameter("ID_USUARIO", id_usuario_token),
                new SqlParameter("ACAO", "UPDATE")
                ).ToListAsync();
    
                return resultado;
            }
    
            [Authorize]
            [Route("api/${objTabela.nomeTabela}/exclui")]
            public async Task&lt;List&lt;${nomeTabelaUp}_Result&gt;&gt; Post${objTabela.nomeTabela}_exclui(${objTabela.nomeTabela} ${objTabela.nomeTabela})
            {
                db.Configuration.ProxyCreationEnabled = false;
                db.Configuration.LazyLoadingEnabled = false;
    
                // Busca o id_usuario referente ao token
                IOwinContext ctx = Request.GetOwinContext();
                int id_usuario_token = Convert.ToInt32(ctx.Authentication.User.Claims.FirstOrDefault(x =&gt; x.Type == "UserID").Value);
    
                var resultado = await db.Database.SqlQuery&lt;${nomeTabelaUp}_Result&gt;(
                "${nomeTabelaUp} ${procParams}, @ID_USUARIO, @ACAO",
                ${sqlParams},
                new SqlParameter("ID_USUARIO", id_usuario_token),
                new SqlParameter("ACAO", "DELETE")
                ).ToListAsync();
    
                return resultado;
            }
    
            public object NullHandler(object instance)
            {
                if (instance != null)
                    return instance;
    
                return DBNull.Value;
            }
    
            public object IntNullHandler(int instance)
            {
                if (instance != 0)
                    return instance;
    
                return DBNull.Value;
            }
    
            public object Int2NullHandler(int? instance)
            {
                if (instance != 0)
                    return instance;
    
                return DBNull.Value;
            }
    
            public object IntLongNullHandler(Int64 instance)
            {
                if (instance != 0)
                    return instance;
    
                return DBNull.Value;
            }`;
    },
    geraJavascript(objTabela) {
      let nomeTabelaUp = objTabela.nomeTabela.toUpperCase();

      // colunas da proc
      let arrayCol = [];
      let arrayColPk = [];
      objTabela.colunas.forEach((e) => {
        if (e.chaveColuna == "1") {
          arrayColPk.push(
            e.nomeColuna +
              "=${" +
              objTabela.nomeTabela +
              "." +
              e.nomeColuna +
              "}"
          );
        }
        arrayCol.push(e.nomeColuna + ": 0");
      });
      let cols = arrayCol.join(", ");
      let colsPk = "`${endApiFastEad}api/" + objTabela.nomeTabela + "/lista?";
      colsPk += arrayColPk.join("&");
      colsPk += "`";

      return `
        let ${objTabela.nomeTabela} = {
            ${cols}
        }
        let options = (method) => {
            if (method == "GET") {
                return {
                    headers: {
                        'Authorization': 'Bearer ' + retornaToken(),
                        'Content-Type': 'application/json; charset=utf-8'
                    },
                    method: "GET"
                }
            } else {
                return {
                    headers: {
                        'Authorization': 'Bearer ' + retornaToken(),
                        'Content-Type': 'application/json; charset=utf-8'
                    },
                    method: "POST",
                    body: JSON.stringify(${objTabela.nomeTabela})
                }
            }
        }
        
        // Método select
        fetch(${colsPk}, options("GET"))
            .then(resp => resp.json())
            .then(data => {
                let obj = JSON.parse(JSON.stringify(data));
                console.log(o)
        });    
        // Método insert
        fetch(endApiFastEad + "api/${objTabela.nomeTabela}/insere", options("POST"))
            .then(resp => resp.json())
            .then(data => {
                let obj = JSON.parse(JSON.stringify(data));
                console.log(o)
        });  
        // Método atualiza
        fetch(endApiFastEad + "api/${objTabela.nomeTabela}/atualiza", options("POST"))
            .then(resp => resp.json())
            .then(data => {
                let obj = JSON.parse(JSON.stringify(data));
                console.log(o)
        });  

        // Método exclui
        fetch(endApiFastEad + "api/${objTabela.nomeTabela}/exclui", options("POST"))
            .then(resp => resp.json())
            .then(data => {
                let obj = JSON.parse(JSON.stringify(data));
                console.log(o)
        });  
        `;
    },
    geraProc(objTabela) {
      let nomeTabelaUp = objTabela.nomeTabela.toUpperCase();

      // param da proc
      let arrayProcParams = [];
      objTabela.colunas.forEach((e) => {
        if (e.tamanhoColuna != "") {
          arrayProcParams.push(
            "@" +
              e.nomeColuna.toUpperCase() +
              " " +
              e.tipoColuna.toUpperCase() +
              "(" +
              e.tamanhoColuna.toUpperCase() +
              ") = NULL"
          );
        } else {
          arrayProcParams.push(
            "@" +
              e.nomeColuna.toUpperCase() +
              " " +
              e.tipoColuna.toUpperCase() +
              " = NULL"
          );
        }
      });
      let procParams = arrayProcParams.join(", ");

      // colunas da proc
      let arrayColParams = [];
      let arrayColVarParams = [];
      let arrayColVarPk = [];
      let arrayColUpdate = [];
      objTabela.colunas.forEach((e) => {
        if (e.chaveColuna != "1") {
          arrayColParams.push(e.nomeColuna);
          arrayColVarParams.push("@" + e.nomeColuna.toUpperCase());
          arrayColUpdate.push(
            e.nomeColuna +
              " = IsNull(@" +
              e.nomeColuna.toUpperCase() +
              ", " +
              e.nomeColuna +
              ")"
          );
        } else {
          arrayColVarPk.push(
            e.nomeColuna + " = @" + e.nomeColuna.toUpperCase()
          );
        }
      });
      let colParams = arrayColParams.join(", ");
      let colVarParams = arrayColVarParams.join(", ");
      let colVarPk = arrayColVarPk.join(" AND ");
      let colUpdate = arrayColUpdate.join(", ");
      return `
        CREATE PROCEDURE [dbo].[STO_${objTabela.nomeTabela.toUpperCase()}_CRUD]
        ${procParams}, 
        @ID_USUARIO INT = NULL,
        @ACAO VARCHAR(6) = NULL
        AS
            BEGIN
            IF (@ACAO = 'INSERT')
            BEGIN
                INSERT INTO fastead.${objTabela.nomeTabela}
                (${colParams})
                VALUES
                (${colVarParams});
                
                SELECT * FROM fastead.${objTabela.nomeTabela} WHERE ${colVarPk};
            END
            ELSE IF (@ACAO = 'SELECT')
            BEGIN
                SELECT * FROM fastead.${objTabela.nomeTabela} WHERE ${colVarPk};
            
            END
            ELSE IF (@ACAO = 'UPDATE')
            BEGIN
                UPDATE fastead.${objTabela.nomeTabela}
                SET
                ${colUpdate}
                WHERE ${colVarPk};
                SELECT * FROM fastead.${objTabela.nomeTabela} WHERE ${colVarPk};
            END
            ELSE IF (@ACAO = 'DELETE')
            BEGIN
                DELETE FROM fastead.${objTabela.nomeTabela} WHERE ${colVarPk};
                SELECT * FROM fastead.${objTabela.nomeTabela} WHERE ${colVarPk};
            
        END
        
        END
        GO
        };
        `;
    },
    geraTabela(objTabela) {
      let nomeTabelaUp = objTabela.nomeTabela.toUpperCase();
      // param da proc
      let arrayProcParams = [];
      let arrayChaves = [];
      let arrayChavesClustered = [];
      objTabela.colunas.forEach((e) => {
        let nulo = "NULL";
        if (e.chaveColuna == "1") {
          nulo = "IDENTITY(1,1) NOT NULL";
          arrayChaves.push(e.nomeColuna);
          arrayChavesClustered.push("[" + e.nomeColuna + "] ASC");
        }

        if (e.tamanhoColuna == "") {
          arrayProcParams.push(
            "[" + e.nomeColuna + "] [" + e.tipoColuna + "] " + nulo
          );
        } else {
          arrayProcParams.push(
            "[" +
              e.nomeColuna +
              "] [" +
              e.tipoColuna +
              "](" +
              e.tamanhoColuna +
              ") " +
              nulo
          );
        }
      });
      let procParams = arrayProcParams.join(", ");
      let chaves = arrayChaves.join("_");
      let chavesClustered = arrayChavesClustered.join(", ");
      return `
        CREATE TABLE [fastead].[${objTabela.nomeTabela}](
            ${procParams}
        CONSTRAINT [Pk_${objTabela.nomeTabela}_${chaves}] PRIMARY KEY CLUSTERED 
        (
            ${chavesClustered}
        )WITH (PAD_INDEX = OFF, STATISTICS_NORECOMPUTE = OFF, IGNORE_DUP_KEY = OFF, ALLOW_ROW_LOCKS = ON, ALLOW_PAGE_LOCKS = ON) ON [PRIMARY]
        ) ON [PRIMARY]
        GO
    `;
    },
    // Leitor de tabela
    lerTabela() {
      let erros = []
      let arrayGenerico = []
      let varGenerica = ""
      this.fastColunas = []
      this.fastChavesPrimarias = []
      if (this.fastTabelaLide.split("CREATE TABLE").length) {
        this.fastTabela = this.fastTabelaLide.split("CREATE TABLE")[1].split("(")[0].trim()
        arrayGenerico = this.fastTabelaLide.split("NULL,")
        arrayGenerico.forEach(e => {
          if (e.indexOf("CREATE TABLE") != "-1") {
            varGenerica = e.split("](")[1] + "NULL"
            this.fastColunas.push(varGenerica.trim())
          } else if (e.indexOf("CONSTRAINT") == "-1") {
            this.fastColunas.push(e.trim() + " NULL")
          } else {
            this.fastChavesPrimarias = e.split("(")[1].split(")")[0].trim().split(",")
          }
        })

        this.geraTemplateVue();

      } else {
        erros.push("Tabela não identificada")
      }
    },
    limparLerTabela() {
      this.fastTabelaLide = ""
      this.fastTabela = "Aguardando leitura..."
      this.fastColunas = []
      this.fastChavesPrimarias = []
    },
    geraTemplateVue() {
      this.fastRota = this.fastRota.replaceAll(":id_plataforma", "' + $route.params.id_plataforma + '")

      let palavrasTitulo = this.fastTituloPagina.split(" ")
      let ultimaPalavraTitulo = ""
      let ultimaPalavraTituloUp = ""
      if (palavrasTitulo.length > 1) {
        this.fastTituloPagina = palavrasTitulo[0] + " <span>"
        palavrasTitulo.forEach((e, index) => {
          if (index) this.fastTituloPagina += " "+e
          if (index + 1 == palavrasTitulo.length) {
            this.fastTituloPagina += "</span>"
            ultimaPalavraTitulo = e.toLowerCase()
            ultimaPalavraTituloUp = e.toLowerCase().charAt(0).toUpperCase() + e.toLowerCase().slice(1)
          }
        })
      } else {
        this.fastTituloPagina = "<span>" + this.fastTituloPagina + "</span>"
      }

      let th = ""
      let td = ""
      let vars = ""

      this.fastColunas.forEach(e => {        
        th += `<th class="text-center"><small class="font-weight-bold">${e.split(" ")[0].replace(/[\[\]]+/g, '')}</small></th>`
        td += `<td class="align-middle text-center">{{e.${e.split(" ")[0].replace(/[\[\]]+/g, '')}}}</td>`
        vars += `${e.split(" ")[0].replace(/[\[\]]+/g, '')}: "",
                `
      })

      th += '<th class="text-center"><small class="font-weight-bold">Ações</small></th>'
      td += `
                                      <td class="text-center align-middle">                                  
                                        <button class="btn btn-sm btn-primary pt-0 pb-0 mr-2" @click="exibeModalEditar${ultimaPalavraTituloUp}(e)">
                                            <small>Editar</small>
                                        </button>
                                        <button class="btn btn-sm btn-danger pt-0 pb-0 mr-2" @click="exibeModalExcluir${ultimaPalavraTituloUp}(e)">
                                          <small>Excluir</small>
                                        </button>
                                      </td>`

      let inputsNovo = ""
      let inputsEditar = ""
      let validaValoresNovo = ""
      let validaValoresEditar = ""

      this.fastColunas.forEach(e => {
        let col = e.split(" ")[0].replace(/[\[\]]+/g, '')
        let tipo = e.split(" ")[1].replace(/[\[\]]+/g, '')       
        let nulo = e.split(" ")[2].replace(/[\[\]]+/g, '')
        //if (nulo.indexOf("IDENTITY") != "-1") nulo = e.split(" ")[3].replace(/[\[\]]+/g, '')
        if (nulo.trim() == "NOT" && nulo.indexOf("IDENTITY") == "-1") {
          validaValoresNovo += `
                if (!this.fast${ultimaPalavraTituloUp}Novo.${col}) erros.push("Campo obrigatório: ${col}")`
          validaValoresEditar += `
                if (!this.fast${ultimaPalavraTituloUp}Editar.${col}) erros.push("Campo obrigatório: ${col}")`
        }
        if (tipo.indexOf("(") != "-1") tipo = tipo.split("(")[0]
        switch (tipo) {
          case "int":
            inputsNovo += `
                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                        <label>${col}</label>
                        <input v-model="fast${ultimaPalavraTituloUp}Novo.${col}" type="int" class="form-control"/>
                      </div>`
            inputsEditar += `
                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                        <label>${col}</label>
                        <input v-model="fast${ultimaPalavraTituloUp}Editar.${col}" type="int" class="form-control"/>
                      </div>`
            break;
          case "money":
            inputsNovo += `
                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                        <label>${col}</label>
                        <input v-model="fast${ultimaPalavraTituloUp}Novo.${col}" type="number" min="1" step="any" class="form-control"/>
                      </div>`
            inputsEditar += `
                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                        <label>${col}</label>
                        <input v-model="fast${ultimaPalavraTituloUp}Editar.${col}" type="number" min="1" step="any" class="form-control"/>
                      </div>`
            break;
          case "date":
            inputsNovo += `
                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                        <label>${col}</label>
                        <input v-model="fast${ultimaPalavraTituloUp}Novo.${col}" type="date" class="form-control"/>
                      </div>`
            inputsEditar += `
                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                        <label>${col}</label>
                        <input v-model="fast${ultimaPalavraTituloUp}Editar.${col}" type="date" class="form-control"/>
                      </div>`
            break;
          case "datetime":
            inputsNovo += `
                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                        <label>${col}</label>
                        <input v-model="fast${ultimaPalavraTituloUp}Novo.${col}" type="datetime" class="form-control"/>
                      </div>`
            inputsEditar += `
                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                        <label>${col}</label>
                        <input v-model="fast${ultimaPalavraTituloUp}Editar.${col}" type="datetime" class="form-control"/>
                      </div>`
            break;
          case "text":
            inputsNovo += `
                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                        <label>${col}</label>
                        <textarea v-model="fast${ultimaPalavraTituloUp}Novo.${col}" class="form-control"></textarea>
                      </div>`
            inputsEditar += `
                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                        <label>${col}</label>
                        <textarea v-model="fast${ultimaPalavraTituloUp}Editar.${col}" class="form-control"></textarea>
                      </div>`
            break;
          case "nvarchar":
            inputsNovo += `
                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                        <label>${col}</label>
                        <input v-model="fast${ultimaPalavraTituloUp}Novo.${col}" type="text" class="form-control"/>
                      </div>`
            inputsEditar += `
                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                        <label>${col}</label>
                        <input v-model="fast${ultimaPalavraTituloUp}Editar.${col}" type="text" class="form-control"/>
                      </div>`
            break;
          case "varchar":
            inputsNovo += `
                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                        <label>${col}</label>
                        <input v-model="fast${ultimaPalavraTituloUp}Novo.${col}" type="text" class="form-control"/>
                      </div>`
            inputsEditar += `
                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                        <label>${col}</label>
                        <input v-model="fast${ultimaPalavraTituloUp}Editar.${col}" type="text" class="form-control"/>
                      </div>`
            break;
          case "nchar":
            inputsNovo += `
                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                        <label>${col}</label>
                        <input v-model="fast${ultimaPalavraTituloUp}Novo.${col}" type="text" class="form-control"/>
                      </div>`
            inputsEditar += `
                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                        <label>${col}</label>
                        <input v-model="fast${ultimaPalavraTituloUp}Editar.${col}" type="text" class="form-control"/>
                      </div>`
            break;
          case "char":
            inputsNovo += `
                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                        <label>${col}</label>
                        <input v-model="fast${ultimaPalavraTituloUp}Novo.${col}" type="text" class="form-control"/>
                      </div>`    
            inputsEditar += `
                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                        <label>${col}</label>
                        <input v-model="fast${ultimaPalavraTituloUp}Editar.${col}" type="text" class="form-control"/>
                      </div>`         
            break;
          case "bit":
            inputsNovo += `
                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                          <label>${col}</label>
                          <div class="fd-app-meus-cursos mt-0 ml-1">
                              <div class="fd-app-curso-aulas-list mt-0">
                                <div
                                  class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                                >
                                  <label class="switch">
                                    <input
                                      v-model="fast${ultimaPalavraTituloUp}Novo.${col}"
                                      checked="checked"
                                      type="checkbox"
                                    />
                                    <span class="slider round" />
                                  </label>
                                </div>
                              </div>
                            </div>
                        </div>`          
            inputsEditar += `
                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                          <label>${col}</label>
                          <div class="fd-app-meus-cursos mt-0 ml-1">
                              <div class="fd-app-curso-aulas-list mt-0">
                                <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                  <label class="switch">
                                    <input
                                      v-model="fast${ultimaPalavraTituloUp}Editar.${col}"
                                      checked="checked"
                                      type="checkbox"
                                    />
                                    <span class="slider round" />
                                  </label>
                                </div>
                              </div>
                          </div>
                      </div>`          
            break;
          default:
            inputsNovo += `
                        <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                          <label>${col}</label>
                          <input v-model="fast${ultimaPalavraTituloUp}Novo.${col}" type="text" class="form-control"/>
                        </div>`
            inputsEditar += `
                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                        <label>${col}</label>
                        <input v-model="fast${ultimaPalavraTituloUp}Editar.${col}" type="text" class="form-control"/>
                      </div>`
            break;
        }
      });
      this.fastVueTemplateEmpty = `
      <template>
        <div></div>
      </template>
      <script>
        import { settings } from "@/config";
        import methods from "@/methods";

        export default {
          name: "${this.fastNomeComponente}",
          components: {},
          mixins: [methods],
          data: function () {
            return {
              // Variáveis aqui
            };
          },
          mounted: function () {

          },
          methods: {
          
          },
        };
      <\/script>
      <style scope>
      </style>
      `
      if (this.fastTipoPagina == "HomeInternoAdmin") {
        this.html = `
        <template>
          <div v-if="$store.state.fastPermissoes.administrador == 'S' && $store.state.fastPermissoes.ativo == 'S'">
            <div class="fd-app-wrap">
              <div class="container">
                <!-- breadcrumb -->
                <nav>
                  <ul class="fd-app-breadcrumb">
                    <li class="fd-app-breadcrumb-item">
                      <a href="/minhas-plataformas" class="aluno_font_color">Minhas Plataformas</a>
                    </li>
                    <li class="fd-app-breadcrumb-item">
                      <a class="aluno_font_color" :href="'/plataforma/' + $route.params.id_plataforma" >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
                    </li>
                    <li class="fd-app-breadcrumb-item">
                      <a class="aluno_font_color" :href="'/plataforma/' + $route.params.id_plataforma + '/admin'" >Modo admin</a>
                    </li>
                    <li class="fd-app-breadcrumb-item active">
                      <a class="aluno_font_color" :href="'${this.fastRota}'">${this.fastTituloPagina}</a>
                    </li>
                  </ul>
                </nav>
                <!-- /breadcrumb -->
              </div>
              <div class="fd-mode-adm-content mt-4">
                <div class="container">
                  <div class="row">
                    <div id="body_conteudo_modo_admin" class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0">
                      <!-- fd-app-welcome -->
                      <section class="fd-app-welcome text-left">
                        <h1 class="aluno_font_color">${this.fastTituloPagina}</h1>
                        <img v-lazy="require('@/assets/images/form-effect-01.png')" />
                      </section>
                      <!-- /fd-app-welcome -->
                      <!-- Content -->
                      <div class="row">
                        <div class="col-12 mt-2">
                          <div class="card shadow mb-4">
                            <div class="card-header py-3">
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-6">
                                  <h6 class="m-0 font-weight-bold text-primary">Lista de ${ultimaPalavraTitulo}</h6>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                                  <button class="btn btn-sm btn-primary" @click.prevent="showModal('modalCriar${ultimaPalavraTituloUp}')">
                                    <small>+ Criar ${ultimaPalavraTitulo}</small>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="card-body">
                              <div class="row">
                                <div class="col-12 table-responsive mt-2">
                                  <table class="table table-sm">
                                    <thead class="thead-dark">
                                      <tr class="text-center">
                                        ${th}
                                      </tr>
                                    </thead>
                                    <tbody v-if="fast${ultimaPalavraTituloUp}.length">
                                      <tr v-for="(e, index) in fast${ultimaPalavraTituloUp}" :key="index">
                                        ${td}
                                      </tr>
                                    </tbody>
                                    <tbody v-else>
                                      <tr>
                                        <td colspan="${this.fastColunas.length + 1}" class="text-center">Nenhum ${ultimaPalavraTitulo} cadastrado</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- /Content -->
                      <div class="mt-5 mb-5">
                        <a class="btn btn-red-hollow" :href="'/plataforma/' + $route.params.id_plataforma + '/admin'">
                          <b-icon-chevron-double-left></b-icon-chevron-double-left> Voltar
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modals -->
            <modal name="modalCriar${ultimaPalavraTituloUp}" :shift-y="0.1" height="auto" :width="modalWidthSm" :scrollable="true" :adaptative="true">
              <div class="row p-4">
                <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
                  <h4>Novo ${ultimaPalavraTitulo}</h4>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
                  <a class="btn btn-secondary" href="#" @click.prevent="hideModal('modalCriar${ultimaPalavraTituloUp}')">
                    <i class="fa fa-times" aria-hidden="true" />
                  </a>
                </div>
                <div class="col-12 p-4">
                  <div class="row">
                    ${inputsNovo}
                    <div class="col-12 mt-4">
                      <div class="form-group mb-0">
                        <button href="#" type="button" class="btn btn-primary" @click="criar${ultimaPalavraTituloUp}()">Criar ${ultimaPalavraTitulo}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </modal>

            <modal name="modalEditar${ultimaPalavraTituloUp}" :shift-y="0.1" height="auto" :width="modalWidthSm" :scrollable="true" :adaptative="true">
              <div class="row p-4">
                <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
                  <h4>Editar ${ultimaPalavraTitulo}</h4>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
                  <a
                    class="btn btn-secondary"
                    href="#"
                    @click.prevent="hideModal('modalEditar${ultimaPalavraTituloUp}')"
                  >
                    <i class="fa fa-times" aria-hidden="true" />
                  </a>
                </div>
                <div class="col-12 p-4">
                  <div class="row">
                    ${inputsEditar}
                    <div class="col-12 mt-4">
                      <div class="form-group mb-0">
                        <button href="#" type="button" class="btn btn-primary" @click="editar${ultimaPalavraTituloUp}()">Confirmar alterações</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </modal>

            <modal name="modalExcluir${ultimaPalavraTituloUp}" :scrollable="true" height="auto" :shift-y="0.1" :focus-trap="true" :adaptive="true">
              <div class="row p-4">
                <div class="col-6 pl-4 pr-4">
                  <h4>Excluir ${ultimaPalavraTitulo}?</h4>
                </div>
                <div class="col-6 pl-4 pr-4 text-right">
                  <a class="btn btn-secondary" href="#" @click.prevent="hideModal('modalExcluir${ultimaPalavraTituloUp}')">
                    <i class="fa fa-times" aria-hidden="true" />
                  </a>
                </div>
                <div class="col-12 p-4">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group mb-0">
                        <button href="#" type="button" class="btn btn-danger" @click.prevent="excluir${ultimaPalavraTituloUp}()">Confirmar</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </modal>

          </div>
        </template>
      `
      
      } else if (this.fastTipoPagina == "HomeInternoAluno") {
        this.html = `
        <template>
          <div>
            <header class="fd-app-mode-ead-header">
              <div class="fd-app-mode-ead-header-bg" :style="{ backgroundImage: 'url(' + $store.state.fastPlataforma.banner_plataforma + ')', backgroundColor: '#000' }"/>
              <div class="fd-app-mode-ead-header-overlay">
                <div class="container">
                  <div class="row justify-content-between align-items-center">
                    <div class="col-sm-12 col-md-12 col-lg-8">
                      <h1 :style="{ color: $store.state.fastPersonalizacao.titulo_banner_color ? $store.state.fastPersonalizacao.titulo_banner_color : '#fff', textShadow: '1px 1px #000' }">{{$store.state.fastPersonalizacao.titulo_banner ? $store.state.fastPersonalizacao.titulo_banner : ''}}</h1>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4">
                      <mural-avisos></mural-avisos>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <!-- fd-wrap -->
            <div class="fd-app-wrap">
              <div class="container">
                <!-- breadcrumb -->
                <nav>
                  <ul class="fd-app-breadcrumb fast-plataforma-iuea-hide">
                    <li class="fd-app-breadcrumb-item">
                      <a href="/minhas-plataformas" class="aluno_font_color">Minhas Plataformas</a>
                    </li>
                    <li class="fd-app-breadcrumb-item">
                      <a :href="'/plataforma/' + $route.params.id_plataforma" class="aluno_font_color" >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
                    </li>
                    <li class="fd-app-breadcrumb-item">
                      <a class="aluno_font_color" :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'" >Modo aluno</a>
                    </li>
                    <li class="fd-app-breadcrumb-item active">
                      <a href="#">${this.fastTituloPagina}</a>
                    </li>
                  </ul>                 
                </nav>
                <!-- /breadcrumb -->
              </div>
              <div class="fd-mode-ead-content">
                <div class="container">
                  <div class="row">
                    <component :is="HomeInternoAlunoMenuLateral" />
                    <div class="col-sm-12 col-md-12 col-lg-11 fd-mode-ead-content-body">
                      <div class="fd-mode-ead-content mt-0 fast-iuea-ml-20">
                        <div class="container">
                          <div class="row justify-content-center">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                  <!-- fd-app-welcome -->
                                  <section class="fd-app-welcome text-left fast-plataforma-iuea-hide">
                                    <h1 class="aluno_font_color">
                                      ${this.fastTituloPagina}
                                    </h1>
                                    <p class="aluno_font_color">escolha como você deseja entrar em contato conosco</p>
                                    <div class="fd-welcome-form-effect fast-plataforma-iuea-hide">
                                      <img :src="require('@/assets/images/form-effect-01.png')" class="mt-2"/>
                                    </div>
                                  </section>
                                  <!-- /fd-app-welcome -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Conteúdo principal -->
                  <div class="row">
                    <div class="col-12 mt-2">
                      <div class="card shadow mb-4">
                        <div class="card-header py-3">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                              <h6 class="m-0 font-weight-bold text-primary">Lista de ${ultimaPalavraTitulo}</h6>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                              <button class="btn btn-sm btn-primary" @click.prevent="showModal('modalCriar${ultimaPalavraTituloUp}')">
                                <small>+ Criar ${ultimaPalavraTitulo}</small>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-12 table-responsive mt-2">
                              <table class="table table-sm">
                                <thead class="thead-dark">
                                  <tr class="text-center">
                                    ${th}
                                  </tr>
                                </thead>
                                <tbody v-if="fast${ultimaPalavraTituloUp}.length">
                                  <tr v-for="(e, index) in fast${ultimaPalavraTituloUp}" :key="index">
                                    ${td}
                                  </tr>
                                </tbody>
                                <tbody v-else>
                                  <tr>
                                    <td colspan="${this.fastColunas.length + 1}" class="text-center">Nenhum ${ultimaPalavraTitulo} cadastrado</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /Conteúdo principal -->
                  <div class="mt-5 mb-5">
                    <a class="btn btn-red-hollow" :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'">
                      <b-icon-chevron-double-left></b-icon-chevron-double-left> Voltar
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!-- /fd-wrap -->
            <!-- Modals -->
            <modal name="modalCriar${ultimaPalavraTituloUp}" :shift-y="0.1" height="auto" :width="modalWidthSm" :scrollable="true" :adaptative="true">
              <div class="row p-4">
                <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
                  <h4>Novo ${ultimaPalavraTitulo}</h4>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
                  <a class="btn btn-secondary" href="#" @click.prevent="hideModal('modalCriar${ultimaPalavraTituloUp}')">
                    <i class="fa fa-times" aria-hidden="true" />
                  </a>
                </div>
                <div class="col-12 p-4">
                  <div class="row">
                    ${inputsNovo}
                    <div class="col-12 mt-4">
                      <div class="form-group mb-0">
                        <button href="#" type="button" class="btn btn-primary" @click="criar${ultimaPalavraTituloUp}()">Criar ${ultimaPalavraTitulo}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </modal>

            <modal name="modalEditar${ultimaPalavraTituloUp}" :shift-y="0.1" height="auto" :width="modalWidthSm" :scrollable="true" :adaptative="true">
              <div class="row p-4">
                <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
                  <h4>Editar ${ultimaPalavraTitulo}</h4>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
                  <a
                    class="btn btn-secondary"
                    href="#"
                    @click.prevent="hideModal('modalEditar${ultimaPalavraTituloUp}')"
                  >
                    <i class="fa fa-times" aria-hidden="true" />
                  </a>
                </div>
                <div class="col-12 p-4">
                  <div class="row">
                    ${inputsEditar}
                    <div class="col-12 mt-4">
                      <div class="form-group mb-0">
                        <button href="#" type="button" class="btn btn-primary" @click="editar${ultimaPalavraTituloUp}()">Confirmar alterações</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </modal>

            <modal name="modalExcluir${ultimaPalavraTituloUp}" :scrollable="true" height="auto" :shift-y="0.1" :focus-trap="true" :adaptive="true">
              <div class="row p-4">
                <div class="col-6 pl-4 pr-4">
                  <h4>Excluir ${ultimaPalavraTitulo}?</h4>
                </div>
                <div class="col-6 pl-4 pr-4 text-right">
                  <a class="btn btn-secondary" href="#" @click.prevent="hideModal('modalExcluir${ultimaPalavraTituloUp}')">
                    <i class="fa fa-times" aria-hidden="true" />
                  </a>
                </div>
                <div class="col-12 p-4">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group mb-0">
                        <button href="#" type="button" class="btn btn-danger" @click.prevent="excluir${ultimaPalavraTituloUp}()">Confirmar</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </modal>
          </div>
        </template>        
      `
      
      }
      
      this.methods = `
            async get${ultimaPalavraTituloUp}(id_plataforma){
              this.promiseGetFastApi("api/${this.fastTabela.replace(/[\[\]]+/g, '').split(".")[1]}/lista", "id_plataforma=" + id_plataforma).then(obj => {
                if (obj.length) this.fast${ultimaPalavraTituloUp} = obj
                else    
                    this.fast${ultimaPalavraTituloUp} = [];
                this.$store.state.fastCarregando = false;
              }).catch(e => { 
                this.exibeToasty("Erro ao buscar ${ultimaPalavraTitulo}", "error");
                this.$store.state.fastCarregando = false;
            })
            },
            async criar${ultimaPalavraTituloUp}() {
                this.$store.state.fastCarregando = true;
                let erros = []
                ${validaValoresNovo}

                if (erros.length) {
                    erros.forEach(e => this.exibeToasty(e, "error"))
                } else {
                    this.promiseInserirFastApi(this.fast${ultimaPalavraTituloUp}Novo, "${this.fastTabela.replace(/[\[\]]+/g, '').split(".")[1]}").then((res) => {
                        this.exibeToasty("${ultimaPalavraTituloUp} criado com sucesso", "success");
                        this.hideModal("modalCriar${ultimaPalavraTituloUp}");
                        this.fast${ultimaPalavraTituloUp} = res;
                        this.$store.state.fastCarregando = false;
                    }).catch((e) => {
                        this.exibeToasty("Erro ao criar ${ultimaPalavraTitulo}", "error");
                        this.$store.state.fastCarregando = false;
                    });
                }      
            },
            exibeModalEditar${ultimaPalavraTituloUp}(${ultimaPalavraTitulo}) {
              this.fast${ultimaPalavraTituloUp}Editar = ${ultimaPalavraTitulo};
              this.showModal("modalEditar${ultimaPalavraTituloUp}");
            },
            async editar${ultimaPalavraTituloUp}() {
              this.$store.state.fastCarregando = true;
              let erros = []
                ${validaValoresEditar}

                if (erros.length) {
                    erros.forEach(e => this.exibeToasty(e, "error"))
                } else {
                    this.promiseAtualizarFastApi(this.fast${ultimaPalavraTituloUp}Editar, "${this.fastTabela.replace(/[\[\]]+/g, '').split(".")[1]}").then(e => {
                        this.exibeToasty("Salvo com sucesso", "success");                
                        this.hideModal("modalEditar${ultimaPalavraTituloUp}");
                        this.$store.state.fastCarregando = false;
                    }).catch(e => {
                        this.exibeToasty("Erro ao salvar alterações", "error");
                        this.$store.state.fastCarregando = false;
                    });
                }        
            },
            exibeModalExcluir${ultimaPalavraTituloUp}(${ultimaPalavraTitulo}) {
              this.fast${ultimaPalavraTituloUp}Editar = ${ultimaPalavraTitulo};
              this.showModal("modalExcluir${ultimaPalavraTituloUp}");
            },
            async excluir${ultimaPalavraTituloUp}(){
                this.$store.state.fastCarregando = true;
                this.promiseExcluirFastApi(this.fast${ultimaPalavraTituloUp}Editar, "${this.fastTabela.replace(/[\[\]]+/g, '').split(".")[1]}").then(e => {
                    this.exibeToasty("${ultimaPalavraTituloUp} excluído com sucesso", "success");        
                    this.get${ultimaPalavraTituloUp}(this.$route.params.id_plataforma);   
                    this.hideModal("modalExcluir${ultimaPalavraTituloUp}");
                }).catch(e => {
                    this.exibeToasty("Erro ao excluir", "error");
                    this.$store.state.fastCarregando = false;
                });
            },
      `;  
      if (this.fastTipoPagina == "HomeInternoAdmin") {
        this.script = `
        <script>
        import { settings } from "@/config";
        import methods from "@/methods";

        export default {
          name: "${this.fastNomeComponente}",
          components: {},
          mixins: [methods],
          data: function () {
            return {
              modalWidthSm: this.isMobile() ? "80%" : "30%",
              modalWidthMd: this.isMobile() ? "90%" : "50%",
              modalWidthLg: this.isMobile() ? "100%" : "90%",
              // ${ultimaPalavraTituloUp}
              fast${ultimaPalavraTituloUp}: [],
              fast${ultimaPalavraTituloUp}Novo: {
                ${vars}
              },
              fast${ultimaPalavraTituloUp}Editar: {
                ${vars}
              },
            };
          },
          mounted: function () {
            if (this.$route.params.id_plataforma) {
              this.getDependenciasVisaoAdmin(this.$route.params.id_plataforma)
                .then(() => {
                  if (this.$store.state.fastPermissoes.ativo == "S" && this.$store.state.fastPermissoes.administrador == "S") {
                    // Recupera ${ultimaPalavraTitulo}
                    this.get${ultimaPalavraTituloUp}(this.$route.params.id_plataforma);            
                  } else {
                    this.$store.state.fastCarregando = false;
                    this.exibeToasty("Você não possui permissões", "error");
                  }
                })
                .catch((e) => {
                  this.exibeToasty(e, "error");
                  this.$store.state.fastCarregando = false;
                });
            } else {
              this.$store.state.fastCarregando = false;
              this.exibeToasty("Você não possui permissões", "error");
            }
          },
          methods: {
            ${this.methods}
          },
        };
        <\/script>
      `

      } else if (this.fastTipoPagina == "HomeInternoAluno") {
        this.script = `
        <script>
        import { settings } from "@/config";
        import methods from "@/methods";

        export default {
          name: "${this.fastNomeComponente}",
          components: {},
          mixins: [methods],
          data: function () {
            return {
              modalWidthSm: this.isMobile() ? "80%" : "30%",
              modalWidthMd: this.isMobile() ? "90%" : "50%",
              modalWidthLg: this.isMobile() ? "100%" : "90%",
              // ${ultimaPalavraTituloUp}
              fast${ultimaPalavraTituloUp}: [],
              fast${ultimaPalavraTituloUp}Novo: {
                ${vars}
              },
              fast${ultimaPalavraTituloUp}Editar: {
                ${vars}
              },
            };
          },
          computed: {
            HomeInternoAlunoMenuLateral() {
              return () =>
                import(
                  \`@/templates/\${this.fastTemplate}/components/HomeInternoAlunoMenuLateral.vue\`
                );
            },
          },
          mounted: function () {
            if (this.$route.params.id_plataforma) {
              this.getDependenciasVisaoAluno(this.$route.params.id_plataforma)
              .then(() => {
                  // Iniciar aqui
                  this.$store.state.fastCarregando = false;
              })
              .catch((e) => {
                this.exibeToasty(e, "error");
                this.$store.state.fastCarregando = false;
              });
            } else {
              this.$store.state.fastCarregando = false;
              this.exibeToasty("Você não possui permissões", "error");
            }
          },
          methods: {
            ${this.methods}
          },
        };
        <\/script>
      `
      }
      
      this.style = `
        <style scope>
        <\/style>
      `

      this.fastVueTemplateFull = this.html +
      `` +
      this.script +
      ``
      this.style;

      this.geraApiController(this.fastTabela.replace(/[\[\]]+/g, '').split(".")[1], this.fastColunas)
      this.geraApiProc(this.fastTabela.replace(/[\[\]]+/g, '').split(".")[1], this.fastColunas, this.fastChavesPrimarias)
    },
    geraApiController(tabela, colunas) {
      let nomeTabelaUp = "STO_" + tabela.toUpperCase() + "_CRUD";

      // param da proc
      let arrayProcParams = [];
      colunas.forEach((e) => {
        let col = e.split(" ")[0].replace(/[\[\]]+/g, '')
        arrayProcParams.push("@" + col.toUpperCase());
      });
      let procParams = arrayProcParams.join(", ");

      // Sql da proc
      let arraySqlParams = [];
      colunas.forEach((e) => {
        let col = e.split(" ")[0].replace(/[\[\]]+/g, '')
        arraySqlParams.push(`
                new SqlParameter("${col.toUpperCase()}", ${tabela}.${col})`);    
      });
      let sqlParams = arraySqlParams.join(", ");
      
      let pks = ""
      this.fastChavesPrimarias.forEach((p, index) => {
          if (index) {
            pks += `, int ${p.split(" ")[0].replace(/[\[\]]+/g, '').trim()}`
          }
          else {
            pks = `int ${p.split(" ")[0].replace(/[\[\]]+/g, '').trim()}`
          }

      })

      let controllerTemp = `
            [Authorize]
            [Route("api/${tabela}/lista")]
            public async Task&lt;List&lt;${nomeTabelaUp}_Result&gt;&gt; Get${tabela}_lista(${pks})
            {
                db.Configuration.ProxyCreationEnabled = false;
                db.Configuration.LazyLoadingEnabled = false;
    
                // Busca o id_usuario referente ao token
                IOwinContext ctx = Request.GetOwinContext();
                int id_usuario_token = Convert.ToInt32(ctx.Authentication.User.Claims.FirstOrDefault(x =&gt; x.Type == "UserID").Value);
    
                var resultado = await db.Database.SqlQuery&lt;${nomeTabelaUp}_Result&gt;(
                "${nomeTabelaUp} ${procParams}, @ID_USUARIO, @ACAO",
                ${sqlParams},
                new SqlParameter("ID_USUARIO", id_usuario_token),
                new SqlParameter("ACAO", "SELECT")
                ).ToListAsync();
    
                return resultado;
            }
    
            [Authorize]
            [Route("api/${tabela}/insere")]
            public async Task&lt;List&lt;${nomeTabelaUp}_Result&gt;&gt; Post${tabela}_insere(${tabela} ${tabela})
            {
                db.Configuration.ProxyCreationEnabled = false;
                db.Configuration.LazyLoadingEnabled = false;
    
                // Busca o id_usuario referente ao token
                IOwinContext ctx = Request.GetOwinContext();
                int id_usuario_token = Convert.ToInt32(ctx.Authentication.User.Claims.FirstOrDefault(x =&gt; x.Type == "UserID").Value);
    
                var resultado = await db.Database.SqlQuery&lt;${nomeTabelaUp}_Result&gt;(
                "${nomeTabelaUp} ${procParams}, @ID_USUARIO, @ACAO",
                ${sqlParams},
                new SqlParameter("ID_USUARIO", id_usuario_token),
                new SqlParameter("ACAO", "INSERT")
                ).ToListAsync();
    
                return resultado;
            }
    
            [Authorize]
            [Route("api/${tabela}/atualiza")]
            public async Task&lt;List&lt;${nomeTabelaUp}_Result&gt;&gt; Post${tabela}_atualiza(${tabela} ${tabela})
            {
                db.Configuration.ProxyCreationEnabled = false;
                db.Configuration.LazyLoadingEnabled = false;
    
                // Busca o id_usuario referente ao token
                IOwinContext ctx = Request.GetOwinContext();
                int id_usuario_token = Convert.ToInt32(ctx.Authentication.User.Claims.FirstOrDefault(x =&gt; x.Type == "UserID").Value);
    
                var resultado = await db.Database.SqlQuery&lt;${nomeTabelaUp}_Result&gt;(
                "${nomeTabelaUp} ${procParams}, @ID_USUARIO, @ACAO",
                ${sqlParams},
                new SqlParameter("ID_USUARIO", id_usuario_token),
                new SqlParameter("ACAO", "UPDATE")
                ).ToListAsync();
    
                return resultado;
            }
    
            [Authorize]
            [Route("api/${tabela}/exclui")]
            public async Task&lt;List&lt;${nomeTabelaUp}_Result&gt;&gt; Post${tabela}_exclui(${tabela} ${tabela})
            {
                db.Configuration.ProxyCreationEnabled = false;
                db.Configuration.LazyLoadingEnabled = false;
    
                // Busca o id_usuario referente ao token
                IOwinContext ctx = Request.GetOwinContext();
                int id_usuario_token = Convert.ToInt32(ctx.Authentication.User.Claims.FirstOrDefault(x =&gt; x.Type == "UserID").Value);
    
                var resultado = await db.Database.SqlQuery&lt;${nomeTabelaUp}_Result&gt;(
                "${nomeTabelaUp} ${procParams}, @ID_USUARIO, @ACAO",
                ${sqlParams},
                new SqlParameter("ID_USUARIO", id_usuario_token),
                new SqlParameter("ACAO", "DELETE")
                ).ToListAsync();
    
                return resultado;
            }
    
            public object NullHandler(object instance)
            {
                if (instance != null)
                    return instance;
    
                return DBNull.Value;
            }
    
            public object IntNullHandler(int instance)
            {
                if (instance != 0)
                    return instance;
    
                return DBNull.Value;
            }
    
            public object Int2NullHandler(int? instance)
            {
                if (instance != 0)
                    return instance;
    
                return DBNull.Value;
            }
    
            public object IntLongNullHandler(Int64 instance)
            {
                if (instance != 0)
                    return instance;
    
                return DBNull.Value;
            }`;
    
      this.fastController = this.htmlDecode(controllerTemp)
    },
    htmlDecode(input){
      var e = document.createElement('div');
      e.innerHTML = input;
      return e.childNodes[0].nodeValue;
    },
    geraApiProc(tabela, colunas) {
      let nomeTabelaUp = tabela.toUpperCase();

      // param da proc
      let arrayProcParams = [];
      colunas.forEach((e) => {
        let col = e.split(" ")[0].replace(/[\[\]]+/g, '')
        let tipo = e.split(" ")[1].replace(/[\[\]]+/g, '')
        let tamanhoColuna = ""
        if (tipo.indexOf("(") != "-1") {
          tamanhoColuna = tipo.split("(")[1].replace(/\)+/g, '').trim()
          tipo = tipo.split("(")[0].trim()                
        }
        let nulo = e.split(" ")[2].replace(/[\[\]]+/g, '')
        //if (nulo.indexOf("IDENTITY") != "-1") nulo = e.split(" ")[3].replace(/[\[\]]+/g, '')

        if (tamanhoColuna != "") {
          arrayProcParams.push(
            "@" +
              col.toUpperCase() +
              " " +
              tipo.toUpperCase() +
              "(" +
              tamanhoColuna.toUpperCase() +
              ") = NULL"
          );
        } else {
          arrayProcParams.push(
            "@" +
              col.toUpperCase() +
              " " +
              tipo.toUpperCase() +
              " = NULL"
          );
        }
        
      });
      let procParams = arrayProcParams.join(", ");

      // colunas da proc
      let arrayColParams = [];
      let arrayColVarParams = [];
      let arrayColVarPk = [];
      let arrayColUpdate = [];

      colunas.forEach((e) => {
        let col = e.split(" ")[0].replace(/[\[\]]+/g, '').trim()
        let pk = false
        this.fastChavesPrimarias.forEach(p => {
          if (col == p.split(" ")[0].replace(/[\[\]]+/g, '').trim()) pk = true
        })

        if (!pk) {
          arrayColParams.push(col);
          arrayColVarParams.push("@" + col.toUpperCase());
          arrayColUpdate.push(
            col +
              " = IsNull(@" +
              col.toUpperCase() +
              ", " +
              col +
              ")"
          );
        } else {
          arrayColVarPk.push(
            col + " = @" + col.toUpperCase()
          );
        }
      });
      let colParams = arrayColParams.join(", ");
      let colVarParams = arrayColVarParams.join(", ");
      let colVarPk = arrayColVarPk.join(" AND ");
      let colUpdate = arrayColUpdate.join(", ");
      this.fastProc = `
        CREATE PROCEDURE [dbo].[STO_${tabela.toUpperCase()}_CRUD]
        ${procParams}, 
        @ID_USUARIO INT = NULL,
        @ACAO VARCHAR(6) = NULL
        AS
            BEGIN
            IF (@ACAO = 'INSERT')
            BEGIN
                INSERT INTO fastead.${tabela}
                (${colParams})
                VALUES
                (${colVarParams});
                
                SELECT * FROM fastead.${tabela} WHERE ${colVarPk};
            END
            ELSE IF (@ACAO = 'SELECT')
            BEGIN
                SELECT * FROM fastead.${tabela} WHERE ${colVarPk};
            
            END
            ELSE IF (@ACAO = 'UPDATE')
            BEGIN
                UPDATE fastead.${tabela}
                SET
                ${colUpdate}
                WHERE ${colVarPk};
                SELECT * FROM fastead.${tabela} WHERE ${colVarPk};
            END
            ELSE IF (@ACAO = 'DELETE')
            BEGIN
                DELETE FROM fastead.${tabela} WHERE ${colVarPk};
                SELECT * FROM fastead.${tabela} WHERE ${colVarPk};
            
        END
        
        END
        GO
        `;
    }
  },
};
</script>

<style>
</style>